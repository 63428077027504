import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
const Research = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;
    const [activeTab, setActiveTab] = useState(1);
    const [bannerData, tabData] = data
    
    const bannerImg = typeof (bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const mobileBannerImg = bannerData?.relationships?.components[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof (bannerData?.relationships?.components[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components[0]?.bannerImage?.alt?.length > 0 ? bannerData?.relationships?.components[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof (bannerData?.relationships?.components[0]?.title) !== "undefined" && bannerData?.relationships?.components[0]?.title?.length > 0 ? bannerData?.relationships?.components[0]?.title : "";
    const bannerSubtitle = typeof (bannerData?.relationships?.components[0]?.subtitle) !== "undefined" && bannerData?.relationships?.components[0]?.subtitle?.length > 0 ? bannerData?.relationships?.components[0]?.subtitle : "";

    const tabDataArr = tabData?.relationships?.components ? tabData.relationships.components : []
    
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/research.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section">
                    <section class="inner_common_banner">
                        <picture className="banner_img">
                          <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                          <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div class="banner_content text_left text_dark">
                          <div class="container">
                            <h1>{bannerTitle}</h1>
                            <p>{bannerSubtitle}</p>
                          </div>
                        </div>
                      </section>
                    <section className="section_bg py-3 researchMainWrap">
                        <div className="researchCommonTab">
                        <div className="container">
                            <div className="bgPlusIcon">
                            <div className="py-4">
                                <h2>Research Department</h2>
                            </div>  
                            <div className="container aboutContainer">
                                <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                    {
                                    typeof (tabDataArr) !== "undefined" && tabDataArr.map((el, index) => {
                                        return (
                                        <li role="presentation" className="nav-item" key={index}>
                                            <a
                                            onClick={() => setActiveTab(index + 1)}
                                            className={`nav-link ${activeTab === index + 1 ? 'active' : ''}`}
                                            href={`#${el?.field_title}`}
                                            data-toggle="tab"
                                            role="tab"
                                            aria-controls={`${el?.field_title}`}
                                            aria-selected="true"
                                            >
                                            {el?.field_title}
                                            </a>
                                        </li>
                                        )
                                    })
                                    }
                                </ul>
                                </div>
                                <div className="container">
                                <div className="tab-content mt-3">
                                    {
                                    typeof (tabDataArr) !== "undefined" && tabDataArr.map((el, index) => {

                                        const data = typeof (el?.text?.processed) !== "undefined" && el?.text?.processed.length > 0 ? el?.text?.processed : "";

                                        return (
                                        <div
                                            key={index}
                                            className={`tab-pane ${activeTab === index + 1 ? 'active' : ''}`}
                                            id={el?.field_title}
                                            role="tabpanel"
                                        >
                                            <div className="card">
                                            <div className="row">
                                                <div className="col-md-12">
                                                <div className="cardInnerWrap" dangerouslySetInnerHTML={{ __html: data }} />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                    }
                                </div>
                                </div>
                            </div>  
                        </div> 
                        </div> 
                    </section>  
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query researchDepartment {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/research$/"}}}) {

    edges {

      node {

        id

        title

        path {

          alias

        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatag_normalized {

          attributes {

            content

            name
            property

          }

        }
        metatags_on_page: field_meta_tags{
          canonical_url
        }
        relationships {

          components: field_component_type {

            __typename

            ...reParagraphSection

          }

        }

      }

    }

  }

}

 

fragment reParagraphSection on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...reParagraphBanner

      ...reParagraphTitleAndDescription

    }

  }

}

 

fragment reParagraphBanner on paragraph__banner {

  id

  title: field_title

  subtitle: field_subtitle

  bannerImage: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    bannerImage: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

}

 

fragment reParagraphTitleAndDescription on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

  field_text {

    processed

  }

}
`

export default Research